import Typography from "@mui/material/Typography";
import React from "react";

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      {/*<Link color="inherit" href="https://mui.com/">*/}
      Kerenfamily.Net{' '}
      {/*</Link>{' '}*/}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export default Copyright;