import React, { useState } from 'react';
import API from '../api';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Loader from "../Loader";
import Copyright from "../Copyright";

const defaultTheme = createTheme();

function ForgotPass() {
  const [formData, setFormData] = useState("");
  const [errors, setErrors] = useState("");
  const [formErrors, setFormErrors] = useState("");
  const [alerts, setAlerts] = useState("");
  const [loader, setLoader] = useState(false);

  const validateForm = () => {
    let valid = true;

    if (!formData) {
      setFormErrors('Username/Email Address is required');
      valid = false;
    }

    setLoader(false);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    if (validateForm()) {
      void API.post(`/forgotpassword`, {email: formData}, {
        headers: {'Content-Type': 'application/json'}
      })
        .then(() => {
          setAlerts("Email sent successfully");
        })
        .catch(e => {
console.log("error", e);
          setLoader(false);

          setErrors("Error had happen while Reset Password")
        })
    }
    else {
      console.log('Failed to send form');
    }
  };

  const handleChange = (e) => {
    setFormData(e.target.value);
  };

  return (
    <ThemeProvider theme={defaultTheme}>

      {loader && <Loader/>}

      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <h1>Keren family Net</h1>

          <Typography component="h1" variant="h5">
            Forgot Password
          </Typography>

          {
            errors !== "" &&
            <div className="alert alert-danger" role="alert">
              {errors}
            </div>
          }
          {
            alerts !== "" &&
            <div className="alert alert-info" role="alert">
              {alerts}
            </div>
          }

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              fullWidth
              label="Username / Email"
              name="username"
              value={formData.username}
              onChange={handleChange}
              error={Boolean(formErrors)}
              helperText={formErrors}
              margin="normal"
            />
            {
              // errors !== "" &&
              // <div className="alert alert-danger" role="alert">
              //   {errors}
              // </div>
            }

            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Reset Password
            </Button>

          </Box>
        </Box>

        <Copyright sx={{mt: 8, mb: 4}}/>

      </Container>
    </ThemeProvider>
  );
}

export default ForgotPass;