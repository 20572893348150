import React, { useContext, useState } from 'react';
import API from '../api';
import {UserContext} from '../utils/UserContext';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Loader from "../Loader";
import {Grid} from "@mui/material";
import Copyright from "../Copyright";

const defaultTheme = createTheme();

function Login() {
  const { updateUserCredentials} = useContext(UserContext);
  const [formData, setFormData] = useState({
    username: '',
    password: '',
    rememberMe: false,
  });
  const [errors, setErrors] = useState({
    username: '',
    password: '',
  });
  const [genErr, setGenErr] = useState("");
  const [loader, setLoader] = useState(false);

  const validateForm = () => {
    let valid = true;
    const newErrors = {username: '', password: ''};

    if (!formData.username) {
      newErrors.username = 'Username is required';
      valid = false;
    }

    // Password strength check
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{6,}$/;
    if (!formData.password || !passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 6 characters with at least one uppercase and one lowercase letter';
      valid = false;
    }

    setErrors(newErrors);
    setLoader(false);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    if (validateForm()) {
      // attempt to login
      void API.post(`/users/login`, {email: formData.username, password: formData.password}, {
        headers: {'Content-Type': 'application/json'}
      })
        .then(({data}) => {
          const newCredentials = {
            token: data.token,
            name: data.user.name,
            email: data.user.email,
            role: data.user.role
          };

          updateUserCredentials(newCredentials);
          window.localStorage.setItem("user", JSON.stringify(newCredentials));

          window.location.href = '/';
        }).catch(e => {
          // console.log("error while login", e);
          setLoader(false);
          if (e.response!==undefined) {
            if (e.response.status===400) {
              setGenErr("User/password is wrong.");
            }
          }

          setGenErr("Error had happen while logging in.")
        })
  }
  else {
    console.log('Login failed');
  }
};

  const handleChange = (e) => {
    const {name, value, checked} = e.target;
    setFormData({
      ...formData,
      [name]: name === 'rememberMe' ? checked : value,
    });
  };

  return (
    <ThemeProvider theme={defaultTheme}>

      {loader && <Loader/>}

      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <h1>Keren family Net</h1>

          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon/>
          </Avatar>

          <Typography component="h1" variant="h5">
            Sign in
          </Typography>

          {
            genErr !== "" &&
            <div className="alert alert-danger" role="alert">
              {genErr}
            </div>
          }

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            {/*<TextField error id="outlined-error" label="Error" defaultValue="Hello World" />*/}
            {/*<TextField margin="normal" required fullWidth id="email" label="Email Address" name="email" autoComplete="email" autoFocus />*/}
            {/*<TextField margin="normal" required fullWidth name="password" label="Password" type="password" id="password" autoComplete="current-password" />*/}
            <TextField
              fullWidth
              label="Username / Email"
              name="username"
              value={formData.username}
              onChange={handleChange}
              error={Boolean(errors.username)}
              helperText={errors.username}
              margin="normal"
            />
            <TextField
              fullWidth
              type="password"
              label="Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
              margin="normal"
              sx={{mt: 2}}
            />

            {/*<FormControlLabel control={<Checkbox value="remember" color="primary"/>} label="Remember me"/>*/}

            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}}>
              Sign In
            </Button>

            <Grid container>
              <Link href="/forgotPass" variant="body2">
                Forgot password?
              </Link>
            </Grid>

          </Box>
        </Box>

        <Copyright sx={{mt: 8, mb: 4}}/>

      </Container>
    </ThemeProvider>
  );
}

export default Login;