import React, { useEffect, useState, useContext } from "react"
import { useNavigate } from "react-router-dom";
import { UserContext } from '../../utils/UserContext';
import { Button } from "react-bootstrap";
import CardElement from "../../parts/CardElement";
import API from "../../api";
import Loader from "../../Loader";
import classes from './Recipe.module.css';

const Recipes = () => {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const [ receiptsList, setReceiptsList ] = useState([]);
  const [ loader, setLoader ] = useState(false);
  const [ formErr, setFormErr ] = useState('');
  const [ filterSelected, setFilterSelected ] = useState('all');
  const navigate = useNavigate();

  useEffect(() => {
    setLoader(true);

    if (token!=="") {
      void API.get(`/recipes`, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({ data }) => {
          setReceiptsList(data);
          setLoader(false);
        }).catch(e => {
          console.log("error while getting recipes", e);
          setFormErr("Error while getting Recipes");
          setLoader(false);
        })
    }
  }, [token]);

  const handleCreateNew = () => {
    navigate("/Recipes/create");
  }

  const handleCategoryChange = (category) => {
    setLoader(true);
console.log("category", category);
    setFilterSelected(category);
    void API.post(`/recipes/category`,
      {category},
      {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(({data}) => {
        // console.log("returned data:", data);
        setReceiptsList(data);

        setLoader(false);
      }).catch(e => {
        console.log("error: ", e);
        setLoader(false);
      })
  }

  const FilterBox = () => {
    return (
      <div className={classes.filterBox}>
        <div className={"btn " + (filterSelected==='all' ? 'btn-info' : 'btn-secondary')}
            onClick={() => {
              handleCategoryChange("all");
            }}>הכל</div>
          <div className={"btn " + (filterSelected==='morning' ? 'btn-info' : 'btn-secondary')}
             onClick={() => {
               handleCategoryChange("morning");
             }}>בוקר</div>
          <div className={"btn " + (filterSelected==='lunch' ? 'btn-info' : 'btn-secondary')}
             onClick={() => {
               handleCategoryChange("lunch");
             }}>צהריים</div>
          <div className={"btn " + (filterSelected==='evening' ? 'btn-info' : 'btn-secondary')}
             onClick={() => {
               handleCategoryChange("evening");
             }}>ערב</div>
          <div className={"btn " + (filterSelected==='snacks' ? 'btn-info' : 'btn-secondary')}
             onClick={() => {
               handleCategoryChange("snacks");
             }}>חטיפים</div>
          <div className={"btn " + (filterSelected==='desserts' ? 'btn-info' : 'btn-secondary')}
             onClick={() => {
               handleCategoryChange("desserts");
             }}>קינוחים</div>
        </div>
        );
        }

        return (
        <div className="mainContainer">

          {loader && <Loader/>}

          {
            formErr && <div className="alert alert-danger" role="alert">
              {formErr}
            </div>
          }

          <div className={"titleContainer"}>
            <h1>מתכונים</h1>
          </div>

          <div className="contentBlock">
            <Button variant="primary" onClick={handleCreateNew}>צור חדש</Button>
          </div>

          <div className="container text-center">
            <FilterBox/>

            <div className="row">

              {receiptsList.map((item) => (
                <CardElement key={item._id} item={item} role={userCredentials.role}/>
              ))
              }

            </div>
          </div>
        </div>
        );
        }

        export default Recipes;