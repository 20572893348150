import React, { useState, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from './components/pages/Login';
import SideBar from "./components/parts/SideBar/SideBar";
import Home from './components/pages/Home';
import Recipes from "./components/pages/recipes/Recipes";
import RecipeCreate from "./components/pages/recipes/RecipeCreate";
import RecipeView from "./components/pages/recipes/RecipeView";
import RecipeEdit from "./components/pages/recipes/RecipeEdit";
import Users from "./components/pages/users/Users";
import UserCreate from "./components/pages/users/UserCreate";
import Logout from "./components/Logout";
import UserEdit from "./components/pages/users/UserEdit";
import 'bootstrap/dist/css/bootstrap.min.css';
import './tailwind.css';
import classes from './App.module.css';
import ForgotPass from "./components/pages/ForgotPass";
import ResetPassword from "./components/pages/ResetPassword";

function App() {
  const auth = window.localStorage.getItem("user") !== null;
  const [loggedIn, setLoggedIn] = useState(false)
  const [email, setEmail] = useState("")

  if (!auth) {
    return (
      <Routes>
        <Route path="/login" element={<Login setLoggedIn={setLoggedIn} setEmail={setEmail} />} />
        <Route path="/forgotPass" element={<ForgotPass />} />
        <Route path="/resetPassword" element={<ResetPassword />} />

        <Route path='*' exact={true} element={<Login />} />
      </Routes>
    );
  }

  return (
    <Suspense fallback={<div>Loading...</div>} >
      <div className={classes.main} dir="rtl" >

        <div className="sideBar">
          <SideBar />
        </div>

        <div className="m-3">
          <Routes>
            <Route path="/" element={<Home email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />

            <Route path="/users" element={<Users />} />
            <Route path="/user/create" element={<UserCreate />} />
            <Route path="/user/:id/edit" element={<UserEdit />} />
            <Route path="/logout"  element={<Logout />} />

            <Route path="/recipes" element={<Recipes email={email} loggedIn={loggedIn} setLoggedIn={setLoggedIn}/>} />
            <Route path="/recipes/create" element={<RecipeCreate />} />
            <Route path="/recipes/:id/view" element={<RecipeView />} />
            <Route path="/recipes/:id/edit" element={<RecipeEdit />} />

            <Route path='*' exact={true} element={<Home />} />
          </Routes>
        </div>
      </div>
    </Suspense>
  );
}

export default App;