import React, {useEffect, useContext, useState} from 'react';
import {Container, Button} from "react-bootstrap";
import SortableTable from "../../SortableTable/SortableTable";
import {Link} from "react-router-dom";
import API from "../../api";
import {UserContext} from "../../utils/UserContext";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
// import classes from "./Users.module.css";

function Users() {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const navigate = useNavigate();
  const [ usersList, setUsersList ] = useState([ ]);
  const [loader, setLoader] = useState(false);
  const [open, setOpen] = React.useState(false);

  const editBtnClasses = "text-blue-500 rounded-4 border border-gray-400 text-center hover:bg-gray-300 hover:cursor-pointer";
  const deleteBtnClasses = "text-red-500 rounded-4 border border-gray-400 text-center hover:bg-gray-300 hover:cursor-pointer";

  useEffect(() => {
    if (token!=="") {
      setLoader(true);

      void API.get(`/users`, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({ data }) => {
          setUsersList(data);
          setLoader(false);
        }).catch(e => {
          console.log("error while getting users", e);
        })
    }
  }, [token])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = (uid) => {
    setOpen(false);
    handleDelete(uid);
  };

  const handleDelete = (uid) => {
    void API.delete(`/users/${uid}/delete`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(() => {
        window.location.reload(false);
      }).catch(e => {
        console.log("error getting recipe data", e);
      })
  }

  const config = [
    { label: 'שם', render: (item) => item.name, sortValue: (item) => item.name },
    { label: 'מייל', render: (item) => item.email },
    { label: 'תפקיד', render: (item) => item.role },
    // { label: '_id', render: (item) => item._id },
    // {
    //   label: 'Status',
    //   render: (item) =>
    //     <div style={{color:item.status==="active" ? 'green' :
    //         item.status==="not active" ? 'red' : 'yellow'}}>{item.status}</div>,
    //   sortValue: (item) => item.status
    // },
    {
      label: 'פעילות',
      render: (item) =>
        <div className="grid grid-cols-2 gap-2">
          <Link to={`/user/${item._id}/edit`} className={editBtnClasses}>עריכה</Link>
          {
            // item.role!=="admin" && <Link to={`/user/${item._id}/delete`} className={deleteBtnClasses}>מחיקה</Link>
            item.role!=="admin" && (
              <>
                <button onClick={() => {
                  // handleDelete(item._id);
                  handleClickOpen();
                  }} className={deleteBtnClasses}>מחיקה</button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"אישור לפני מחיקה"}
                  </DialogTitle>
                  <DialogActions>
                    <Button onClick={handleClose} variant="secondary">סרב</Button>
                    <Button onClick={() => {
                      handleDeleteClose(item._id);
                    }} autoFocus variant="danger">
                      אשר
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )
          }
        </div>
    }
  ];

  const handleCreateNew = () => {
    navigate("/user/create");
  }

  return (
    <Container>

      <div className="contentBlock">
        <Button variant="primary" onClick={handleCreateNew}>צור חדש</Button>
      </div>

      { loader && <Loader /> }

      <SortableTable data={usersList} config={config} />
    </Container>
  );
}

export default Users;
