import React, {useState, useContext} from 'react';
import { useNavigate } from "react-router-dom";
import {Form, Row, Button} from "react-bootstrap";
import {UserContext} from '../../utils/UserContext';
import API from '../../api';
import Loader from "../../Loader";
import RequiredField from "../../RequiredField";

function RecipeCreate() {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const [ formErr, setFormErr ] = useState('');
  const [ formSuccess, setFormSuccess ] = useState('');

  const [ nameValue, setNameValue ] = useState('');
  const [ source, setSource ] = useState('');
  const [ tags, setTags ] = useState({
    'morning': false,
    'lunch': false,
    'evening': false,
    'snacks': false,
    'desserts': false
  });
  const [ previewImage, setPreviewImage ] = useState(null);
  const [ side1, setSide1 ] = useState(null);
  const [ side2, setSide2 ] = useState(null);

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case 'recipeName':
        setNameValue(value);
        break;
      case 'source':
        setSource(value);
        break;
      case 'morning':
        setTags({...tags, morning: !tags.morning})
        break;
      case 'lunch':
        setTags({...tags, lunch: !tags.lunch})
        break;
      case 'evening':
        setTags({...tags, evening: !tags.evening})
        break;
      case 'snacks':
        setTags({...tags, snacks: !tags.snacks})
        break;
      case 'desserts':
        setTags({...tags, desserts: !tags.desserts})
        break;
      case'previewImage':
        setPreviewImage(e.target.files[0]);
        break;
      case 'side1':
        setSide1(e.target.files[0]);
        break;
      case 'side2':
        setSide2(e.target.files[0]);
        break;
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    else {
      setLoader(true);

      let newRecipe = {
        title: nameValue,
        source: source,
        tags: tags
      }

      if (previewImage!==null) {
        newRecipe = { ...newRecipe, previewImage: previewImage};
      }

      newRecipe = { ...newRecipe, side1: side1};

      if (side2!==null) {
        newRecipe = { ...newRecipe, side2: side2};
      }

      void API.post('/recipes/create', newRecipe, {
        headers: {'Content-Type': 'multipart/form-data', 'Authorization' : 'Bearer '+token}
      })
        .then(() => {
          setTimeout(function() {
            navigate("/Recipes");
          }, 2000);

          setFormSuccess('נשמר בהצלחה.');
        }).catch(() => {
          setLoader(false);
          setFormErr('משהו נכשל בשמירה, נסה שוב מאוחר יותר.');
        })
    }
  }

  return (
    // <form className="container mt-3 mb-3" noValidate validated={validated} onSubmit={handleSubmit}>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>

      { loader && <Loader /> }

      <h1>הוספת מתכון חדש</h1>

      {
        formSuccess && <div className="alert alert-success" role="alert">
          { formSuccess }
        </div>
      }
      {
        formErr && <div className="alert alert-danger" role="alert">
          { formErr }
        </div>
      }

      <Row className="mb-3">
        <Form.Group controlId="recipeName" className="col col-sm-6">
          <Form.Label>שם
            <RequiredField />
          </Form.Label>
          <Form.Control type="name" name="name" required value={nameValue} onChange={handleInputChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            הכנס/י שם תקין.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="source" className="col col-sm-6">
          <Form.Label>מקור
            <RequiredField />
          </Form.Label>
          <Form.Control type="source" name="source" required value={source} onChange={handleInputChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            הכנס/י שם תקין.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group className="col col-sm-6">
          <Form.Label>קטגוריה
            <RequiredField />
          </Form.Label>
          <Form.Check inline reverse label="בוקר" name="morning" type="checkbox" id="morning" onChange={handleInputChange} />
          <Form.Check inline reverse label="צהריים" name="lunch" type="checkbox" id="lunch" onChange={handleInputChange} />
          <Form.Check inline reverse label="ערב" name="evening" type="checkbox" id="evening" onChange={handleInputChange} />
          <Form.Check inline reverse label="חטיפים" name="snacks" type="checkbox" id="snacks" onChange={handleInputChange} />
          <Form.Check inline reverse label="קינוחים" name="desserts" type="checkbox" id="desserts" onChange={handleInputChange} />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="previewImage" className="mb-3">
          <Form.Label>תמונה של המנה (תמונה מקדימה)</Form.Label>
          <Form.Control type="file" onChange={handleInputChange} />
          <Form.Control.Feedback type="invalid">
            ניתן לעלות רק קבצי PDF/JPG/JPEG.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <h5>קבצי המתכוון</h5>
        <Form.Group controlId="side1" className="mb-3">
          <Form.Label>צד ראשון
            <RequiredField />
          </Form.Label>
          <Form.Control type="file" onChange={handleInputChange} required />
          <Form.Control.Feedback type="invalid">
            ניתן לעלות רק קבצי PDF/JPG/JPEG.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="side2" className="mb-3">
          <Form.Label>צד שני
          </Form.Label>
          <Form.Control type="file" onChange={handleInputChange} />
          <Form.Control.Feedback type="invalid">
            ניתן לעלות רק קבצי JPG/JPEG.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          {/*<button type="submit" className="me-4 btn btn-success btn-lg btn-block">Submit</button>*/}
          <Button type="submit">Submit</Button>
        </Form.Group>
      </Row>
    </Form>
  );
}

export default RecipeCreate;
