import React, {useEffect, useState} from 'react';
import API from '../api';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import Loader from "../Loader";
import Avatar from "@mui/material/Avatar";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Copyright from "../Copyright";

const defaultTheme = createTheme();

function ResetPassword() {
  const [formData, setFormData] = useState({
    password: '',
    passwordTwo: ''
  });
  const [errors, setErrors] = useState({
    password: '',
    passwordTwo: ''
  });
  const [alerts, setAlerts] = useState("");
  const [loader, setLoader] = useState(false);
  const [formDisabled, setFormDisabled] = useState(false);
  const [resetToken, setResetToken] = useState("");

  useEffect(() => {
    const queryParameters = new URLSearchParams(window.location.search)
    const uid = queryParameters.get("id");
    const resetToken = queryParameters.get("token");

    void API.post(`/resetpassword`, { uid, resetToken}, {
      headers: {'Content-Type': 'application/json'}
    })
      .then(() => {
        setResetToken(resetToken);
      })
      .catch(({ response }) => {
// console.log("error", response);
        setFormDisabled(true);

        if (response.status === 401) {
          setErrors(response.data);
        }
        else {
          setErrors("Error happened during the validation. Try again.");
        }

        setLoader(false);
      })

  }, []);

  const validateForm = () => {
    let valid = true;
    const newErrors = {password: '', passwordTwo: ''};

    if (!formData.password) {
      newErrors.password = 'Password is required';
      valid = false;
    }
    if (!formData.passwordTwo) {
      newErrors.passwordTwo = 'Validate Password is required';
      valid = false;
    }

    if (formData.password!==formData.passwordTwo) {
      newErrors.passwordTwo = 'Validate Password is not matched';
      valid = false;
    }

    // Password strength check
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{7,}$/;
    if (!formData.password || !passwordRegex.test(formData.password)) {
      newErrors.password = 'Password must be at least 6 characters with at least one uppercase and one lowercase letter';
      valid = false;
    }

    setErrors(newErrors);
    setLoader(false);
    return valid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoader(true);

    if (validateForm() && resetToken!=="") {
      void API.post(`/user/password/set`, {pass: formData.password, pass2: formData.passwordTwo, resetToken}, {
        headers: {'Content-Type': 'application/json'}
      })
        .then(( ) => {
// console.log("saved ok: ", data);
          setAlerts("Password was saved successfully.");

          setTimeout(function() {
            window.location.href = '/';
          }, 3000);
        })
        .catch(( ) => {
// console.log("error:", response);
          setLoader(false);

          setAlerts("Error had happen while logging in.")
        })
    }
    else {
      console.log('Failed to send form');
    }
  }

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  return (
    <ThemeProvider theme={defaultTheme}>

      {loader && <Loader/>}

      <Container component="main" maxWidth="xs">
        <CssBaseline/>
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >

          <h1>Keren family Net</h1>

          <Avatar sx={{m: 1, bgcolor: 'secondary.main'}}>
            <LockOutlinedIcon/>
          </Avatar>

          <Typography component="h1" variant="h5">
            Reset Password
          </Typography>

          {
            alerts !== "" &&
            <div className="alert alert-info" role="alert">
              {alerts}
            </div>
          }

          <Box component="form" onSubmit={handleSubmit} noValidate sx={{mt: 1}}>
            <TextField
              fullWidth
              type="password"
              label="Enter New Password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              error={Boolean(errors.password)}
              helperText={errors.password}
              margin="normal"
              sx={{mt: 2}}
              disabled={formDisabled}
            />
            <TextField
              fullWidth
              type="password"
              label="Retype Password"
              name="passwordTwo"
              value={formData.passwordTwo}
              onChange={handleChange}
              error={Boolean(errors.passwordTwo)}
              helperText={errors.passwordTwo}
              margin="normal"
              sx={{mt: 2}}
              disabled={formDisabled}
            />

            <Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} disabled={formDisabled}>
              Save
            </Button>
          </Box>
        </Box>

        <Copyright sx={{mt: 8, mb: 4}}/>

      </Container>
    </ThemeProvider>
  );
}

export default ResetPassword;