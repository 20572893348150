import React, {useState, useContext, useEffect} from 'react';
import { useNavigate } from "react-router-dom";
import {Form, Row, Button} from "react-bootstrap";
import {UserContext} from '../../utils/UserContext';
import API from '../../api';
import Loader from "../../Loader";
import RequiredField from "../../RequiredField";
import ImagePreview from "../../ImagePreview";
import classes from './RecipeEdit.module.css';

function RecipeEdit() {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;

  const navigate = useNavigate();
  const [ loader, setLoader ] = useState(false);
  const [ validated, setValidated ] = useState(false);
  const [ formErr, setFormErr ] = useState('');
  const [ formSuccess, setFormSuccess ] = useState('');

  const [ nameValue, setNameValue ] = useState('');
  const [ source, setSource ] = useState('');
  const [ tags, setTags ] = useState({
    'morning': false,
    'lunch': false,
    'evening': false,
    'snacks': false,
    'desserts': false
  });

  const [ previewImage, setPreviewImage ] = useState();
  const [ side1, setSide1 ] = useState(null);
  const [ side1Prev, setSide1Prev ] = useState();
  const [ side2, setSide2 ] = useState(null);
  const [ side2Prev, setSide2Prev ] = useState();
  const recipeID = window.location.pathname.replace('/recipes/', '').replace('/edit', '');

  useEffect(() => {
    if (token!==undefined) {
      void API.get(`/recipes/${recipeID}/get`, {
        headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
      })
        .then(({data}) => {
          setNameValue(data.title);
          setSource(data.source);
          setTags(data.tags);

          if (data.previewImage!==undefined) {
            setPreviewImage(btoa(String.fromCharCode(...new Uint8Array(data.previewImage.data.data))));
          }

          if (data.side1.length>0) {
            const side1 = data.side1[0];
            setSide1(side1);
            if (side1.mimetype==="image/jpg" || side1.mimetype==="image/jpeg") {
              setSide1Prev(btoa(String.fromCharCode(...new Uint8Array(side1.data.data))));
            }
          }

          // btoa(String.fromCharCode(...new Uint8Array(data.previewImage)))
          if (data.side2!==undefined) {
            setSide2(data.side2.filename);
            if (data.side2.mimetype==="image/jpg" || data.side2.mimetype==="image/jpeg") {
              setSide2Prev(btoa(String.fromCharCode(...new Uint8Array(data.side2.data.data))));
            }
          }
        }).catch(e => {
          console.log("error while getting recipe:", e);
        })
    }
  }, [recipeID, token])

  const handleInputChange = (e) => {
    const { id, value } = e.target;

    switch (id) {
      case 'recipeName':
        setNameValue(value);
        break;
      case 'source':
        setSource(value);
        break;
      case 'morning':
        setTags({...tags, morning: !tags.morning})
        break;
      case 'lunch':
        setTags({...tags, lunch: !tags.lunch})
        break;
      case 'evening':
        setTags({...tags, evening: !tags.evening})
        break;
      case 'snacks':
        setTags({...tags, snacks: !tags.snacks})
        break;
      case 'desserts':
        setTags({...tags, desserts: !tags.desserts})
        break;
      // case'previewImage':
      //   setPreviewImage(e.target.files[0]);
      //   break;
      case 'side1':
        setSide1(e.target.files[0]);
        break;
      // case 'side2':
      //   setSide2(e.target.files[0]);
      //   break;
      default:
        break;
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
    }
    else {
      setLoader(true);

      let newRecipe = {
        title: nameValue,
        source: source,
        tags: tags
      }

      if (previewImage!==null) {
        newRecipe = { ...newRecipe, previewImage: previewImage};
      }

      newRecipe = { ...newRecipe, side1: side1};

      if (side2!==null) {
        newRecipe = { ...newRecipe, side2: side2};
      }

      void API.post(`/recipes/${recipeID}/update`, newRecipe, {
        headers: {'Content-Type': 'multipart/form-data', 'Authorization' : 'Bearer '+token}
      })
        .then(() => {
          setTimeout(function() {
            navigate("/Recipes");
          }, 2000);

          window.scrollTo(0, 0)
          setFormSuccess('נשמר בהצלחה.');
        }).catch(() => {
          setLoader(false);
          setTimeout(function () {
            setFormErr('');
          }, 5000);
          
          setFormErr('משהו נכשל בשמירה, נסה שוב מאוחר יותר.');
        })
    }
  }

  return (
    // <form className="container mt-3 mb-3" noValidate validated={validated} onSubmit={handleSubmit}>
    <Form noValidate validated={validated} onSubmit={handleSubmit}>

      { loader && <Loader /> }

      <h1>עריכת מתכון</h1>

      {
        formSuccess && <div className="alert alert-success" role="alert">
          { formSuccess }
        </div>
      }
      {
        formErr && <div className="alert alert-danger" role="alert">
          { formErr }
        </div>
      }

      <Row className="mb-3">
        <Form.Group controlId="recipeName" className="col col-sm-6">
          <Form.Label>שם
            <RequiredField />
          </Form.Label>
          <Form.Control type="name" name="name" required value={nameValue} onChange={handleInputChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            הכנס/י שם תקין.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="source" className="col col-sm-6">
          <Form.Label>מקור
            <RequiredField />
          </Form.Label>
          <Form.Control type="source" name="source" required value={source} onChange={handleInputChange} className="form-control" />
          <Form.Control.Feedback type="invalid">
            הכנס/י שם תקין.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group className="col col-sm-6">
          <Form.Label>קטגוריה
            <RequiredField />
          </Form.Label>
          <Form.Check inline reverse label="בוקר" name="morning" type="checkbox" id="morning" onChange={handleInputChange} checked={tags.morning==="true"} />
          <Form.Check inline reverse label="צהריים" name="lunch" type="checkbox" id="lunch" onChange={handleInputChange} checked={tags.lunch==="true"} />
          <Form.Check inline reverse label="ערב" name="evening" type="checkbox" id="evening" onChange={handleInputChange} checked={tags.evening==="true"} />
          <Form.Check inline reverse label="חטיפים" name="snacks" type="checkbox" id="snacks" onChange={handleInputChange} checked={tags.snacks==="true"} />
          <Form.Check inline reverse label="קינוחים" name="desserts" type="checkbox" id="desserts" onChange={handleInputChange} checked={tags.desserts==="true"} />
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="previewImage" className="mb-3">
          <Form.Label>תמונה של המנה (תמונה מקדימה)</Form.Label>
          {
            previewImage!==undefined &&
            <div className={classes.fileSplit}>
              <div>
                <ImagePreview alt={previewImage.fileName} imageData={`data:image/png;base64,${previewImage}`} />
                { previewImage.filename }
              </div>
              <div>
                <Form.Control type="file" onChange={handleInputChange} />
              </div>
            </div>
          }
          {
            previewImage===undefined && <Form.Control type="file" onChange={handleInputChange} />
          }
          <Form.Control.Feedback type="invalid">
            ניתן לעלות רק קבצי PDF/JPG/JPEG.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <h5>קבצי המתכוון</h5>
        <Form.Group controlId="side1" className="mb-3">
          <Form.Label>צד ראשון
            <RequiredField />
          </Form.Label>
          {
            side1Prev!==undefined &&
            <div className={classes.fileSplit}>
              <div>
                <ImagePreview alt={side1.fileName} imageData={`data:image/png;base64,${side1Prev}`} />
              </div>
              <div>
                <Form.Control type="file" onChange={handleInputChange} />
              </div>
            </div>
          }
          {
            side1Prev===undefined && <Form.Control type="file" onChange={handleInputChange} />
          }
          <Form.Control.Feedback type="invalid">
            ניתן לעלות רק קבצי PDF/JPG/JPEG.
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="side2" className="mb-3">
          <Form.Label>צד שני</Form.Label>
          {
            side2Prev!==undefined &&
            <div className={classes.fileSplit}>
              <div>
                <ImagePreview alt={side2.fileName} imageData={`data:image/png;base64,${side2Prev}`} />
              </div>
              <div>
                <Form.Control type="file" onChange={handleInputChange} />
              </div>
            </div>
          }
          {
            side2Prev===undefined && <Form.Control type="file" onChange={handleInputChange} />
          }
          <Form.Control.Feedback type="invalid">
            ניתן לעלות רק קבצי JPG/JPEG.
          </Form.Control.Feedback>
        </Form.Group>
      </Row>

      <Row className="mb-3">
        <Form.Group controlId="formGridCheckbox" className="col col-sm-6">
          {/*<button type="submit" className="me-4 btn btn-success btn-lg btn-block">Submit</button>*/}
          <Button type="submit">Submit</Button>
        </Form.Group>
      </Row>
    </Form>
  );
}

export default RecipeEdit;
