import React, { useContext } from 'react';
import { useNavigate } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import classes from './CardElement.module.css';
import API from "../api";
import {UserContext} from "../utils/UserContext";
// import Avatar from '@mui/material/Avatar';
// import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
// import RestaurantOutlinedIcon from '@mui/icons-material/RestaurantOutlined';
// import NightlightRoundOutlinedIcon from '@mui/icons-material/NightlightRoundOutlined';
// import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';

const CardElement = ({item, role}) => {
  const { userCredentials } = useContext(UserContext);
  const { token } = userCredentials;
  const navigate = useNavigate();
  const previewImage = item.previewImage!==undefined ? btoa(String.fromCharCode(...new Uint8Array(item.previewImage.buffer.data))) : null;
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClose = (recipeID) => {
    setOpen(false);
    handleDelete(recipeID);
  };

  const handleDelete = (recipeID) => {
    void API.delete(`/recipes/${recipeID}/delete`, {
      headers: {'Content-Type': 'application/json', 'Authorization' : 'Bearer '+token}
    })
      .then(() => {
        window.location.reload(false);
      }).catch(e => {
        console.log("error getting recipe data", e);
      })
  }

  const ShowTags = ({tags}) => {
    return Object.entries(tags).map(([key, value]) => {
      if (value==="true") {
        switch (key) {
          case "morning":
            return <div key={key}>בוקר</div>
          case "lunch":
            return <div key={key}>צהריים</div>
          case "evening":
            return <div key={key}>ערב</div>;
          case "snacks":
            return <div key={key}>חטיפים</div>;
          case "desserts":
            return <div key={key}>קינוחים</div>;
          default:
            return null;
        }
      }

      return null;
    })
  }

  return (
    <Card style={{ width: '18rem' }} className="m-2 p-1" key={item._id}>
      {
        previewImage!==null && <Card.Img variant="top" src={`data:image/png;base64,${previewImage}`} />
      }

      <Card.Body>
        <Card.Title>{item.title}</Card.Title>

        <div>
          <div style={{float:'right'}}>קטגוריה:</div>
          <ShowTags tags={item.tags} />
        </div>

        <div className={classes.actionBtns}>
          <Button variant="primary" onClick={() => {
            // console.log("redirect to /Recipes/", item._id);
            navigate(`/recipes/${item._id}/view`);
          }}>פרטים</Button>
          <Button variant="secondary" onClick={() => {
            // console.log("redirect to /Recipes/", item._id);
            navigate(`/recipes/${item._id}/edit`);
          }}>עריכה</Button>
          {
            role==='admin' &&
            <>
              <Button variant="danger" onClick={() => {
                handleClickOpen()
              }}>מחיקה</Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"אישור לפני מחיקה"}
                </DialogTitle>
                <DialogActions>
                  <Button onClick={handleClose} variant="secondary">סרב</Button>
                  <Button onClick={() => {
                    handleDeleteClose(item._id);
                  }} autoFocus variant="danger">
                    אשר
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          }
        </div>
      </Card.Body>
    </Card>
  );
}

export default CardElement;